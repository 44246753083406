import ApiService from "@/core/services/api.service.js";

const productService = {
    getProductList(param) {
        return  new Promise((resolve, reject) => {
          
          var paramStr = ''
          if(param.page != undefined){
            paramStr = '?page='+(param.page+1)
          }else{
            paramStr = '?'
          }
          for (const key in param.filters) {
            if(param.filters[key] != null)
              paramStr += '&' + key + '=' + param.filters[key]
          }
          
          ApiService.get("api/admin/product"+paramStr)
            .then(function(response) {
              if (response.data) {
                resolve(response.data[0]);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              resolve("");
              reject(error)
            });
        });
    },
    getProductById(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/product/detail/"+id)
            .then(function(response) {
              if (response.data.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    createProduct(req) {
        return  new Promise((resolve, reject) => {
            ApiService.post("api/admin/product/create",req)
            .then(function(response) {
              if (response.data.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
              resolve("");
            });
        });
    },
    updateProduct(id,req) {
        return  new Promise((resolve, reject) => {
            ApiService.put("api/admin/product/update/"+id,req)
            .then(function(response) {
              if (response.data.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
              resolve("");
            });
        });
    },
    updateProductFilter(id,req) {
      return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/product/filter/"+id,req)
          .then(function(response) {
            if (response.data.data) {
              resolve(response.data.data);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {
            reject(error)
          });
      });
  },
  getFilterByProductId(id) {
    return  new Promise((resolve, reject) => {
      ApiService.get("api/admin/product/filter/categoryFilter/"+id)
        .then(function(response) {
          if (response !=undefined) {
            resolve(response.data);
          } else {
            resolve("");
          }
        })
        .catch(function(error) {
          reject(error)
        });
    });
  },
  getSelectedFilterByProductId(id) {
    return  new Promise((resolve, reject) => {
      ApiService.get("api/admin/product/filter/"+id)
        .then(function(response) {
          if (response != undefined) {
            resolve(response.data);
          } else {
            resolve("");
          }
        })
        .catch(function(error) {
          reject(error)
        });
    });
  },
}

export default productService;