import ApiService from "@/core/services/api.service.js";

const faqService = {
    getProductFaq(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/product/faq/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getFaqById(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/product/faq/detail/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    createFaq(id,data) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/product/faq/"+id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    updateFaq(data,id) {
        return  new Promise((resolve, reject) => {
          ApiService.put("api/admin/product/faq/"+id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    deleteFaq(id) {
        return  new Promise((resolve, reject) => {
          ApiService.delete("api/admin/product/faq/delete/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },

}

export default faqService;