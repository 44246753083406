<template>
  <b-nav pills>
    <b-nav-item :to="`/product/basic/${pId}`" :active="link === 'basic'"
      >Basic</b-nav-item
    >
    <b-nav-item
      :to="`/product/attribute/${pId}`"
      :active="link === 'attribute'"
      :disabled="!pId"
      >Attribute</b-nav-item
    >
    <b-nav-item :to="`/product/link/${pId}`" :active="link === 'link'" :disabled="!pId"
      >Links</b-nav-item
    >
    <b-nav-item
      :to="`/product/filter/${pId}`"
      :active="link === 'filter'"
      :disabled="!pId"
      >Filters</b-nav-item
    >
    <!-- <b-nav-item v-if="productDetails.product_type == 'frame' && productDetails.prescription_status == 1" :to="`/product/lens-package/${pId}`" :active="link==='lens'" :disabled="!pId">Lens Package</b-nav-item> -->
    <b-nav-item
      v-if="productDetails.varient"
      :to="`/product/options/${pId}`"
      :active="link === 'options'"
      :disabled="!pId"
      >Variants</b-nav-item
    >

    <b-nav-item
      :to="`/product/image-upload/${pId}`"
      :active="link === 'image'"
      :disabled="!pId"
      >Image Upload</b-nav-item
    >
    <b-nav-item :to="`/product/faq/${pId}`" :active="link === 'faq'" :disabled="!pId"
      >FAQ</b-nav-item
    >
    <b-nav-item
      :to="`/product/internal-links/${pId}`"
      :active="link === 'seolinks'"
      :disabled="!pId"
      >SEO Links</b-nav-item
    >
  </b-nav>
</template>

<script>
export default {
  name: "ProductFormNav",
  props: {
    productDetails: Object,
    link: String,
    productId: Number,
  },
  data() {
    return {
      pId: this.productId ? this.productId : "",
    };
  },
};
</script>

<style></style>
