<template>
  <div>
    <b-card no-body>
      <b-card header-tag="header">
        <template v-slot:header>
          <product-form-nav
            link="faq"
            :productDetails="productDetails"
            :productId="id"
          ></product-form-nav>
        </template>

        <form class="form" novalidate="novalidate" id="product_link">
          <b-form-group label-for="input-1">
            <label>Question <sup class="text-danger font-weight-boldest">*</sup></label>
            <b-form-input
              v-model="form.question"
              type="text"
              name="question"
              class="form-control"
              placeholder="EG: Does this product have any waranty period?"
            >
            </b-form-input>
          </b-form-group>

          <b-form-group label-for="input-1">
            <label>Answer <sup class="text-danger font-weight-boldest">*</sup></label>
            <b-form-input
              v-model="form.answer"
              type="text"
              name="answer"
              class="form-control"
              placeholder="EG: Yes"
            >
            </b-form-input>
          </b-form-group>

          <b-form-group label-for="input-1">
            <label>Sort Order <sup class="text-danger font-weight-boldest">*</sup></label>
            <b-form-input
              v-model="form.sort_order"
              type="number"
              name="sort"
              class="form-control"
            ></b-form-input>
          </b-form-group>
          <b-row>
            <b-col lg="4" md="6">
              <b-form-group>
                <label>Status</label>
                <div class="position-relative">
                  <treeselect
                    v-model="form.status"
                    :max-height="200"
                    :default-expand-level="1"
                    :clearable="false"
                    :options="statusOption"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <button
                ref="submit_faq"
                class="btn btn-sm btn-outline-primary font-size-h6"
              >
                <i class="las la-save"></i> Save
              </button>
            </b-col>
          </b-row>
        </form>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import ProductFormNav from "./components/ProductFormNav.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";

import ProductService from "@/core/services/api/product/product";
import FaqService from "@/core/services/api/product/faq";

export default {
  components: {
    ProductFormNav,
  },
  data() {
    return {
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      qid: this.$route.params.qid ? parseInt(this.$route.params.qid) : null,
      statusOption: [
        {
          id: "active",
          label: "Active",
        },
        {
          id: "inactive",
          label: "InActive",
        },
      ],
      form: {
        question: "",
        answer: "",
        sort_order: 0,
        status: "active",
      },
      productDetails: {},
    };
  },
  computed: {},
  created() {
    if (this.qid) this.getFaqById();
    this.getProductById();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Products",
        route: "/products",
      },
      {
        title: "Manage Product : Data",
      },
    ]);
    const signin_form = KTUtil.getById("product_link");
    this.fv = formValidation(signin_form, {
      fields: {
        question: {
          validators: {
            notEmpty: {
              message: "Question is required",
            },
          },
        },
        answer: {
          validators: {
            notEmpty: {
              message: "Answer is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      const submitButton = this.$refs["submit_faq"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right", "disabled");
      if (this.qid == null) {
        this.createFaq(function () {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        });
      } else {
        this.updateFaq(function () {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        });
      }
    });
  },
  methods: {
    async getProductById() {
      var response = await ProductService.getProductById(this.id);
      this.productDetails = response;
    },
    async getFaqById() {
      var vm = this;
      var response = await FaqService.getFaqById(this.qid);
      this.form = response;
    },
    createFaq(callback) {
      var vm = this;
      FaqService.createFaq(vm.id, vm.form)
        .then(function (response) {
          vm.$router.push({ name: "product-faq", param: { id: vm.id } });
        })
        .catch(function (error) {
          callback();
        });
    },
    updateFaq(callback) {
      var vm = this;

      FaqService.updateFaq(vm.form, vm.qid)
        .then(function (response) {
          vm.$router.push({ name: "product-faq", param: { id: vm.id } });
        })
        .catch(function (error) {
          callback();
        });
    },
  },
};
</script>

<style></style>
